.map {
  width: 100%;
  height: 100%;
}

.userMarker {
  width: 15px;
  height: 15px;
  background: var(--backgroundSec);
  border-radius: "100%";
  z-index: 10;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(var(--backgroundSec), 0.5);
  }
}
.sellPointMarker {
  width: 30px;
  height: 30px;
  background-image: url("../../assets/pdvi.svg");
  background-position: center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  transition: all 100ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &.FULL {
    background-image: url("../../assets/pdv-full.svg");
  }
  &.MID {
    background-image: url("../../assets/pdf-mid.svg");
  }
  &.NS {
    background-image: url("../../assets/pdv-empty.svg");
  }
}
