@mixin respond($breakpoint) {
  @if $breakpoint == xs {
    @media only screen and (min-width: 350px) {
      @content;
    } //600px
  }
  @if $breakpoint == sm {
    @media only screen and (min-width: 450px) {
      @content;
    } //600px
  }
  @if $breakpoint == phone {
    @media only screen and (min-width: 600px) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (min-width: 900px) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (min-width: 1220px) {
      @content;
    } //1220px
  }
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1440px) {
      @content;
    } //1220px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 1800px) {
      @content;
    } //1800
  }
}

// @include respond(tab-port) {
//     padding: 0 20px;
//   }
