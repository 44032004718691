@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes enterRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterTop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hoverButton {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes shake {

  10%,
  90% {
    transform: rotate(-10deg);
  }

  20%,
  80% {
    transform: rotate(10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(-10deg);
  }

  40%,
  60% {
    transform: rotate(10deg);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideUp3 {
  0% {
    top: 100%;
  }

  100% {
    top: 0;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp2 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

//     animation: NAME DURATION TIMING DELAY ITERATION(1) DIRECTION(normal) FILL;
//    animation: name duration timing-function delay iteration-count direction fill-mode;