@import "../../utils/colors.scss";
@import "../../utils/media.scss";
@import "../../utils/animation.scss";

.providerInfoModal {
  animation: slideUp2 0.5s ease-in-out 100ms 1 normal backwards;
  position: absolute;
  z-index: 100;
  background: var(--background);
  height: 100%;
  width: 100vw;
  padding: 16px 15px 0 14px;
  z-index: 1;

  @include respond(tab-port) {
    max-width: 375px;
    max-height: 505px;
    right: 30px;
    top: 60px;
    animation: fadeIn 0.5s ease-in-out 200ms 1 normal backwards;
  }

  .modalContainer {
    position: relative;
    width: 100%;
    height: 100%;

    .arrow {
      cursor: pointer;
      margin-bottom: 1.6rem;
      width: 100%;
      height: auto;
      color: #fff;
      display: flex;
      justify-content: flex-start;

      svg {
        fill: #000;
      }

      .back {
        width: 22px;
        height: 19px;
      }
    }

    h2 {
      color: var(--description);
      font-family: "Aller", sans-serif;
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      margin-bottom: 6px;
      text-align: left;
    }

    p {
      color: var(--description);
      font-family: "Aller", sans-serif;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      margin-bottom: 4px;
      text-align: left;
    }

    .productContainer {
      position: absolute;
      height: 60vh;
      width: 100%;
      overflow-y: scroll;
      border: 1px solid rgb(151, 151, 151);
      @include respond(tab-port) {
        max-width: 375px;
        max-height: 300px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
      .productContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .product {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;
          border-radius: 2px;
          height: 43px;
          margin-top: 7px;
          cursor: pointer;

          &:nth-child(1) {
            margin-top: 0;
          }

          p {
            font-family: "Aller", sans-serif;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: center;
            color: #3a3a3a;
            margin-left: 14px;
            font-size: 16px;
          }

          .productCheckContainer {
            margin-right: 11px;

            .oval {
              background-color: #f3f0f0;
              border: 3.2px solid #d5d5d5;
              border-radius: 100%;
              width: 21.4px;
              height: 21.4px;
              margin-right: 9px;

              .tick {
                display: none;
              }
            }

            .checked {
              background-color: #f3f0f0;
              border-radius: 100%;
              width: 21.4px;
              height: 21.4px;
              margin-right: 9px;

              .tick {
                width: 21.4px;
                height: auto;
              }
            }
          }
        }
      }
    }

    .buttonClass {
      color: var(--mainText);
      border: 0;
      box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.27);
      font-family: "Aller", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 35px;
      width: 246px;
      text-align: center;
      background-color: var(--buttons);
      position: absolute;
      bottom: 0;
      left: -14px;
      width: 100vw;
      border-radius: 0;
      height: 51px;
      cursor: pointer;
      outline: none;

      .svg {
        outline: none;
      }

      @include respond(tab-port) {
        max-width: 375px;
        max-height: 300px;
      }
    }
  }
}
