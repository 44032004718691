$orange: #fead3d;
$orange-sec: #f7b500;
$violet: #390640;
$violet-sec: #511958;
$white: #fff;
$black: #000;
$green: #08b674;
$pink: #d1b2db;
$grey: #4b4b4b;
$grey-sec: rgba(176, 176, 176, 0.44);
$shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.27);
$shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2);
$red: #ff214f;
