@import "../../../utils/colors.scss";
@import "../../../utils/media.scss";
@import "../../../utils/animation.scss";

.headerhome {
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0;
  display: flex;
  padding: 0 2rem;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  &.alertOpen {
    top: 90px;
    @include respond(xs) {
      top: 80px;
    }
    @include respond(sm) {
      top: 75px;
    }
    @include respond(phone) {
      top: 60px;
    }
  }

  &.searching {
    justify-content: flex-end;
  }

  @include respond(tab-port) {
    top: 70px;
    padding-right: 56px;
  }
}

.howitworks {
  width: 37px;
  height: 37px;
  background-color: var(--background);
  border-radius: 100%;
  cursor: pointer;
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: "Aller", sans-serif;
    font-size: 2.7rem;
    font-weight: bold;
    letter-spacing: normal;
    color: var(--buttonsSec);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--buttonsSec);
  }

  &.white {
    svg {
      fill: #000;
    }
  }
}
.share {
  width: 106px;
  padding: 0.8rem 0.4rem;
  border-radius: 4px;
  background-color: var(--buttonsSec);
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  box-shadow: $shadow;

  h3 {
    font-family: "Aller", sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.46;
    color: var(--mainText);
  }
}

.copy {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 131px;
  height: 70px;
  border-radius: 6px;
  background-color: rgba(70, 62, 62, 0.81);
  z-index: 10;
  opacity: 0;
  display: none;
  transition: all 500ms ease-in-out;
  visibility: hidden;

  &.open {
    opacity: 1;
    display: inline-block;
    visibility: visible;
  }

  span {
    font-family: "Aller", sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 70px;
    text-align: center;
    color: #ffffff;
  }
}
