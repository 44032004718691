@import "../../../utils/colors.scss";

.footerInput {
  margin-top: 16px;
  background-color: var(--backgroundSec);
  width: 100%;
  padding: 16px 20px;
  border-radius: 4px;
  font-size: 1.6rem;
  font-family: "Aller";
  font-weight: bold;
  border: none;
  outline: none;
  text-align: left;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray);
}
