@import "../../utils/colors.scss";
@import "../../utils/media.scss";
@import "../../utils/animation.scss";

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: "100%";
  min-height: 150px;
  background-color: var(--background);
  padding: 20px;

  @include respond(tab-port) {
    position: absolute;
    left: auto;
    top: 60px;
    right: 38px;
    max-height: 400px;
    max-width: 375px;
  }

  .footerContainer {
    @include respond(tab-port) {
      padding-top: 55px;
    }

    h2 {
      display: none;

      @include respond(tab-port) {
        display: inline-block;
        text-align: left;
        font-family: Aller;
        font-size: 27px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        color: var(--description);
      }
    }

    .description {
      font-family: "Aller", sans-serif;
      font-size: 14px;
      line-height: 1.36;
      text-align: center;
      color: var(--description);
      margin: 0 auto;
      margin-top: 14px;
    }

    .madeItBy {
      margin: 0 auto;
      margin-top: 10px;
      font-family: "Aller", sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white;
      font-size: 1.2rem;

      span {
        a {
          color: $white;
          font-size: 1.2rem;
          font-weight: bold;
          font-family: "Aller", sans-serif;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}

.result {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: "100%";
  min-height: 150px;
  background-color: var(--background);
  padding: 20px;

  @include respond(tab-port) {
    position: absolute;
    left: auto;
    top: 60px;
    right: 30px;
    max-height: 228px;
    max-width: 375px;
    padding-top: 0;
  }

  .resultBox {
    @include respond(tab-port) {
      padding-top: 37px;
    }

    .results {
      font-family: "Aller", sans-serif;
      font-size: 2.3rem;
      text-align: left;
      font-weight: bold;
      font-style: normal;
      color: var(--description);
      margin-bottom: 5px;
    }

    .resultsDescription {
      font-family: "Aller", sans-serif;
      font-size: 1.4rem;
      font-weight: normal;
      color: var(--description);
      text-align: left;
    }

    .resultContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;

      .resultItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        svg {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        span {
          font-family: "Aller", sans-serif;
          font-size: 1.4rem;
          font-weight: normal;
          letter-spacing: normal;
          color: $white;
        }
      }
    }
  }
}
