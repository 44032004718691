@import "../../../utils/colors.scss";

.placesInput {
  margin-top: 16px;
  color: $white;
  background-color: var(--backgroundSec);
  width: 100%;
  padding: 16px 20px;
  border-radius: 4px;
  font-size: 1.6rem;
  font-family: "Aller";
  font-weight: bold;
  border: none;
  outline: none;
  text-align: left;
  position: relative;
  &::placeholder {
    color: var(--gray);
  }
}

.suggestionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 1rem;
}
.suggestion {
  width: 100%;
  border-bottom: 1px solid black;
  padding: 1.5rem 0;
  cursor: pointer;
  p {
    font-size: 1.6rem;
    font-family: "Aller";
    font-weight: bold;
    text-align: left;
    color: $white;
  }
  &:hover {
    background-color: var(--backgroundSec);
  }
}
