@font-face {
  font-family: "Aller";
  font-style: normal;
  font-weight: normal;
  src: local("Aller"), url("./assets/Aller.ttf") format("truetype");
}

@font-face {
  font-family: "AllerBold";
  font-style: normal;
  font-weight: normal;
  src: local("AllerBold"), url("./assets/AllerBold.ttf") format("truetype");
}

:root {
  --townBar: #08b674;
  --buttons: #fead3d;
  --buttonsSec: #f7b500;
  --background: #390640;
  --backgroundSec: #511958;
  --description: #d1b2db;
  --mainText: #390640;
  --highlightText: #f7b500;
  --gray: #b0b0b080;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
}
