.loading {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  background: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
