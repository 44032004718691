@import "../../../utils/colors.scss";

.subSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;

  .title {
    font-family: "Aller", sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.06;
    letter-spacing: normal;
    color: var(--highlightText);

    @media (min-width: 1440px) {
      font-size: 2rem;
    }
  }

  .description {
    margin-top: 7px;
    font-family: "Aller", sans-serif;
    font-size: 1.4rem;
    line-height: 1.36;
    color: $white;
    text-align: left;

    @media (min-width: 1440px) {
      font-size: 1.8rem;
    }
  }
}
