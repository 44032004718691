@import "../../../utils/colors.scss";
@import "../../../utils/media.scss";
@import "../../../utils/animation.scss";

.footerButton {
  width: 100%;
  padding: 16px 20px;
  text-align: center;
  background-color: var(--buttons);
  border-radius: 4px;
  color: var(--mainText);
  font-size: 1.6rem;
  font-family: "Aller";
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;

  @include respond(tab-port) {
    margin-top: 18px;
  }
}
