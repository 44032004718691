@import "../../../utils/colors.scss";
@import "../../../utils/media.scss";
.alert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 0 1.5rem 3rem;
  background-color: var(--townBar);
  z-index: 10;

  h3 {
    font-family: "Aller", sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.36;
    letter-spacing: normal;
    color: $white;
    text-align: left;
    @include respond(tab-port) {
      text-align: center;
    }
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 16px;
  width: 12px;
  height: 12px;
  fill: $white;
  cursor: pointer;
}
