.chart {
  width: 100%;
  height: 100%;
  height: 122px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;
  border-bottom: solid 1px rgba($color: #979797, $alpha: 0.3);
  .chartItem {
    width: 17px;
    border-radius: 3px;
    border: solid 1px #ffffff;
    background-color: #7baaf7;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .info {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    color: #191919;
    display: inline-block;
    padding: 2px 6px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 100ms;
    z-index: 2;
    font-family: "Aller", sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.75;
    color: #3a3a3a;
    text-align: left;
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
}
